import {getChildrenClassForNestedItems} from "../../../default/global/js/nav";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../default/global/js/actionClick";
import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {stickyHeader} from "../../../default/global/js/stickyheader";
import hoverintent from "../../../default/global/js/hoverintent";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {stickyBox} from "../../../default/global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../default/global/js/mobilemenu";
import {
    excludeModal,
    triggerConfirmModalLink,
    triggerModal,
    triggerOpenLinkInModal
} from "../../../default/global/js/modal";
import {initToggleSlide} from "../../../default/global/js/toggle";
import {setSrcVideos} from "../../../default/global/js/video";
import {initScrollbarCardsSwiper, initScrollbars} from "../../../default/global/js/scrollbar";
import {isTouchDevice} from "../../../default/global/js/touchDevice";
import {initCustomSelects} from "../../../default/global/js/customSelect";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {trimInputValues} from "../../../default/global/js/formValidator";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {addTableWrapper} from "../../../default/global/js/tableWrapper";
import {initInputBody} from "../../../default/global/js/inputfile";
import {initImageGallery} from "../../../default/global/js/imageGallery";
import {scrollingBanner} from "../../../default/global/js/scrollingBanner";
import {countUp} from "../../../default/global/js/countUp";
import {initInputMaskDate} from "../../../default/global/js/inputMaskDate";
import {initAjaxCollectionFilter} from "../../../default/global/js/ajaxCollectionFilter";
import {initHistorySlider} from "../../../default/global/js/history";
import {slideDown, slideUp} from "../../../default/global/js/utilities/slide";
import {initRecaptchaForms} from "../../../default/global/js/recaptchaForm";
//import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
function manageTags() {
    const tagsContainers = document.querySelectorAll('.tags:not(.isOpen)');

    tagsContainers.forEach(tagsContainer => {
        const tags = tagsContainer.querySelectorAll('.tag');
        const maxVisibleTags = 3;

        // Prüfen, ob die Klasse 'isOpen' vorhanden ist
        if (tagsContainer.classList.contains('isOpen')) {
            // Alle Tags anzeigen, wenn die Klasse 'isOpen' vorhanden ist
            tags.forEach(tag => {
                tag.style.display = 'inline-flex';
            });
            // Plus- und Minus-Symbole nicht anzeigen
            return;
        }

        if (tags.length > maxVisibleTags) {
            // Tags ausblenden, die nach dem dritten Tag kommen
            for (let i = maxVisibleTags; i < tags.length; i++) {
                tags[i].style.display = 'none';
            }

            // Plus- und Minus-Symbole hinzufügen
            const plusIcon = document.createElement('i');
            plusIcon.classList.add('icon', 'icon-plus');
            const minusIcon = document.createElement('i');
            minusIcon.classList.add('icon', 'icon-minus');
            tagsContainer.appendChild(plusIcon);
            tagsContainer.appendChild(minusIcon);

            // Event-Listener für das Plus- und Minus-Symbol hinzufügen
            plusIcon.addEventListener('click', () => {
                // Alle Tags einblenden
                for (let i = maxVisibleTags; i < tags.length; i++) {
                    tags[i].style.display = 'inline-flex';
                }
                plusIcon.style.display = 'none';
                minusIcon.style.display = 'inline-flex';
            });

            minusIcon.addEventListener('click', () => {
                // Tags ausblenden, die nach dem dritten Tag kommen
                for (let i = maxVisibleTags; i < tags.length; i++) {
                    tags[i].style.display = 'none';
                }
                plusIcon.style.display = 'inline-flex';
                minusIcon.style.display = 'none';
            });

            // Anfangs nur das Plus-Symbol anzeigen
            minusIcon.style.display = 'none';
        }
    });
}


function addHoverClass() {
    const level1Elements = document.querySelectorAll('.js-mainNavigation .level_1.hasChildren');
    const header = document.getElementById('header');

    if (level1Elements.length > 0 && header) {
        level1Elements.forEach(element => {

            element.addEventListener('mouseover', () => {
                header.classList.add('is-active');
                const headerHeight = header.offsetHeight;
                const submenuHeight = calculateSubmenuHeight(element);
                header.style.setProperty('--header-height', headerHeight + (submenuHeight - 4) + 'px');
            });

            element.addEventListener('mouseout', () => {
                header.classList.remove('is-active');
            });

            // Touch events
            element.addEventListener('touchstart', () => {
                header.classList.add('is-active');
                const headerHeight = header.offsetHeight;
                const submenuHeight = calculateSubmenuHeight(element);
                header.style.setProperty('--header-height', headerHeight + (submenuHeight - 4) + 'px');
            });

            element.addEventListener('touchend', () => {
                header.classList.remove('is-active');
            });
        });
    }
}

function calculateSubmenuHeight(parentElement) {
    const submenu = parentElement.querySelector('.hasChildren .megaMenu');
    if (submenu) {
        return submenu.offsetHeight;
    }
    return 0;
}

function toggleLogo() {
    const header = document.getElementById('header');
    const logo = document.querySelector('#header img');

    if (header && logo) {
        const originalLogoSrc = logo.getAttribute('src');
        const newLogoSrc = originalLogoSrc.replace('logo_weiss.svg', 'logo_schwarz.svg');

        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === 'class') {
                    if (header.classList.contains('is-active')) {
                        logo.setAttribute('src', newLogoSrc);
                    } else {
                        logo.setAttribute('src', originalLogoSrc);
                    }
                }
            });
        });

        observer.observe(header, { attributes: true });
    }
}
const setHeaderHeight = () => {
    const header = document.getElementById('header');
    const activeHeader = document.querySelector('#header.is-active');

    if (header && activeHeader) {
        const headerHeight = header.offsetHeight;
        activeHeader.style.setProperty('--header-height', headerHeight + 'px');
        console.log(headerHeight);
    }
};

window.addEventListener('resize', setHeaderHeight);
window.addEventListener('DOMContentLoaded', setHeaderHeight);

window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');

    let breakPointSM = 1024;

    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        })

        mobileMenuClose.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });
    }

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    trimInputValues();

    focusInputGroups();

    initInputBody();

    stickyHeader(stickyHeaderArea);

    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    triggerModal();
    excludeModal();
    triggerOpenLinkInModal();
    triggerConfirmModalLink();

    initToggleSlide();

    for (let el of document.querySelectorAll('.js-mainNavigation li')) {
        hoverintent(el,400);
    }

    for (let el of document.querySelectorAll('#box > div')) {
        hoverintent(el);
    }

    for (let el of document.querySelectorAll('.productScrollbar > .textcontent')) {
        hoverintent(el,200,function (){
            let content = el.querySelector(".productScrollbar__content");
            if(content){
                slideDown(content);
            }
            el.classList.add("hoverintent-finished");
        },function (){
            let content = el.querySelector(".productScrollbar__content");
            if(content){
                slideUp(content);
            }
            el.classList.remove("hoverintent-finished");
        });
    }

    setSrcVideos();

    initSlideshows();

    initImageGallery();

    initScrollbars();

    initSlidecontents();

    initCustomSelects();

    // Tooltip, when necessary
    // import {Tooltip} from "../../../default/global/js/tooltip-custom";

    //var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    //var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //    return new Tooltip(tooltipTriggerEl);
    //});

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }

    lottieLoader();

    addTableWrapper();

    scrollingBanner();

    for (let el of document.querySelectorAll("[data-count-up]")) {
        countUp(el);
    }

    initInputMaskDate();

    initAjaxCollectionFilter();

    addHoverClass();

    toggleLogo();

    manageTags();

    initScrollbarCardsSwiper();

    initHistorySlider();
    initRecaptchaForms();

   // initPhoneCountryFields();


// Event Listener für das Scroll-Ereignis hinzufügen
    window.addEventListener('scroll', updateHeaderDisplay);

// Initiale Prüfung bei Laden der Seite
    document.addEventListener('DOMContentLoaded', updateHeaderDisplay);

});

// Funktion, die die Anzeige des headerTop-Elements basierend auf der Klasse von headerMain ändert
function updateHeaderDisplay() {
    const headerMain = document.querySelector('.headerMain');
    const headerTop = document.querySelector('.headerTop.d-none.d-sm-block');

    if (headerMain && headerTop) {
        if (headerMain.classList.contains('sticky')) {
            headerTop.style.setProperty('display', 'none', 'important');
        } else {
            headerTop.style.display = 'block';
        }
    }
}

// START: js-imageScrollbar

let lastScrollTop = 0; // Speichert die letzte Scroll-Position

function handleScroll() {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollTop > lastScrollTop) {
        // Scrolle nach unten
        activateImageScrollbar();
    } else {
        // Scrolle nach oben
        deactivateImageScrollbar();
    }

    lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // Stellt sicher, dass lastScrollTop nicht negativ wird
}

function activateImageScrollbar() {
    const imageScrollbarWrapper = document.querySelector('.imageScrollbarWrapper');
    if (imageScrollbarWrapper) {
        const rect = imageScrollbarWrapper.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            imageScrollbarWrapper.classList.add('is-active');
        }
    }
}

function deactivateImageScrollbar() {
    const imageScrollbarWrapper = document.querySelector('.imageScrollbarWrapper');
    if (imageScrollbarWrapper) {
        const rect = imageScrollbarWrapper.getBoundingClientRect();
        if (rect.bottom <= 0 || rect.top >= (window.innerHeight || document.documentElement.clientHeight)) {
            imageScrollbarWrapper.classList.remove('is-active');
        }
    }
}

// Füge Event-Listener für das Scrollen hinzu
window.addEventListener('scroll', handleScroll);

// END: js-imageScrollbar

