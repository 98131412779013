import Swiper  from 'swiper';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import {getOptionsFromJsonString} from "./utilities/options";

export function initSlideshows2(query = document.querySelectorAll('.slideshow')) {
    for (let slideshow of query) {
        let options = getOptionsFromJsonString(slideshow.dataset.options, {
            autoplay_interval: 5000,
            eff_interval: 300,
        });
        if(slideshow.classList.contains("slideshow--3")){
            Swiper.use([Autoplay,Pagination]);
            new Swiper(slideshow, {
                loop: true,
                items: 1,
                autoHeight: true,
                direction: "vertical",
                speed: options.eff_interval,
                autoplay: {
                   delay: options.autoplay_interval,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                }
            });
        }
        else if(slideshow.classList.contains("swiperText")){
            Swiper.use([Autoplay]);
            new Swiper(slideshow, {
                loop: true,
                items: 1,
                autoHeight: true,
                direction: "vertical",
                speed: options.eff_interval,
                autoplay: {
                    delay: options.autoplay_interval,
                },
            });
        }
        else if(slideshow.classList.contains("slideshow--2")){
            Swiper.use([Navigation,Pagination]);
            new Swiper(slideshow, {
                loop: true,
                items: 1,
                speed: options.eff_interval,
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                    clickable: true,
                },
                navigation: {
                    nextEl: slideshow.querySelector('.swiper-button-next'),
                    prevEl: slideshow.querySelector('.swiper-button-prev'),
                }
            });
        }
        else{
            Swiper.use([Autoplay, Navigation]);
            new Swiper(slideshow, {
                loop: true,
                items: 1,
                speed: options.eff_interval,
                autoplay: {
                    delay: options.autoplay_interval,
                },
                navigation: {
                    nextEl: slideshow.querySelector('.swiper-button-next'),
                    prevEl: slideshow.querySelector('.swiper-button-prev'),
                }
            });
        }
    }
}




function initializeBackgroundSlideshow(slideshow, options, textSwiper) {
    Swiper.use([Autoplay, Pagination]);
    const backgroundSwiper = new Swiper(slideshow, {
        loop: true,
        items: 1,
        autoHeight: true,
        direction: "vertical",
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
        noSwiping: true,
        noSwipingClass: 'swiper-slide',
        speed: 500,
        autoplay: {
            delay: options.autoplay_interval,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        }
    });

    // Überwache das slideChange Ereignis des Hintergrund-Swipers
    backgroundSwiper.on('slideChange', function () {
        // Wechseln Sie die Folie im Text-Swiper zur gleichen Indexposition wie im Hintergrund-Swiper
        textSwiper.slideTo(backgroundSwiper.realIndex);
    });

    // Überwachen Sie das Autoplay Ereignis des Hintergrund-Swipers
    backgroundSwiper.on('autoplay', function () {
        // Starten Sie das Autoplay des Text-Swipers
        textSwiper.autoplay.start();
    });

    return backgroundSwiper;
}

function initializeTextSlideshow(slideshow, options) {
    Swiper.use([Autoplay]);
    const textSwiper = new Swiper(slideshow, {
        loop: false,
        items: 1,
        autoHeight: true,
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
        direction: "vertical",
        speed: 500,
        autoplay: {
            delay: options.autoplay_interval,
        },
    });

    return textSwiper;
}

function initializeSlideshowWithNavigationAndPagination(slideshow, options) {
    Swiper.use([Navigation, Pagination]);
    new Swiper(slideshow, {
        loop: true,
        items: 1,
        speed: options.eff_interval,
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
            clickable: true,
        },
        navigation: {
            nextEl: slideshow.querySelector('.swiper-button-next'),
            prevEl: slideshow.querySelector('.swiper-button-prev'),
        }
    });
}

function initializeSlideshowWithAutoplayAndNavigation(slideshow, options) {
    Swiper.use([Autoplay, Navigation]);
    new Swiper(slideshow, {
        loop: true,
        items: 1,
        speed: options.eff_interval,
        autoplay: {
            delay: options.autoplay_interval,
        },
        navigation: {
            nextEl: slideshow.querySelector('.swiper-button-next'),
            prevEl: slideshow.querySelector('.swiper-button-prev'),
        }
    });
}


export function initSlideshows(query = document.querySelectorAll('.slideshow')) {
    for (let slideshow of query) {
        let options = getOptionsFromJsonString(slideshow.dataset.options, {
            autoplay_interval: 5000,
            eff_interval: 300,
        });
        if (slideshow.classList.contains("slideshow--3")) {
            const textSlideshow = document.querySelector('.swiperText');
            const textSwiper = initializeTextSlideshow(textSlideshow, options);
            const backgroundSwiper = initializeBackgroundSlideshow(slideshow, options, textSwiper);
        }
        else if (slideshow.classList.contains("swiperText")) {
            initializeTextSlideshow(slideshow, options);
        }
        else if (slideshow.classList.contains("slideshow--2")) {
            initializeSlideshowWithNavigationAndPagination(slideshow, options);
        }
        else {
            initializeSlideshowWithAutoplayAndNavigation(slideshow, options);
        }
    }
}